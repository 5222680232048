import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import brandStyles from "./brand.module.css"
import Brandstamp from "../images/brand-stamp.png"
import Brandrossaniyom from "../images/brand-rossaniyom.png"
import Brandklaas from "../images/brand-klaas.png"
import Brandskplass1995 from "../images/brand-skplass1995.png"
import Brandschool from "../images/brand-school.png"

export default function Brand() {
    return (
        <Container fluid="xl" className={brandStyles.brand_container}>
            <Row>
                <Col sm><center><img className={brandStyles.brand} src={Brandstamp} alt="onigitop" /></center></Col>
                <Col><center><img className={brandStyles.brand} src={Brandschool} alt="onigitop" /></center></Col>
                <Col><center><img className={brandStyles.brand} src={Brandklaas} alt="onigitop" /></center></Col>
                <Col><center><img className={brandStyles.brand} src={Brandskplass1995} alt="onigitop" /></center></Col>
                <Col><center><img className={brandStyles.brand} src={Brandrossaniyom} alt="onigitop" /></center></Col>
            </Row>
        </Container>
    );
}